@font-face {
  font-family: Imported;
  src: url(/src/fonts/Poppins-Regular.ttf);
}
#root{
  font-family: Imported;
}

* {
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.box-shadow {
  color: #fff;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 7px;
  border-color: #004c99;
  background-color: rgba(0, 58, 117, 0.3);
  box-shadow:0px 3px 6px #004c99,inset 0px 2px 8px #141a1f;
}
.marquee {
  height: 30px;	
  overflow: hidden;
  position: relative;
  background: #1a1a1a;
 }
 .marquee div {
  font-size: 12px;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 30px;
  text-align: center;
  /* Starting position */
  -moz-transform:translateX(100%);
  -webkit-transform:translateX(100%);	
  transform:translateX(100%);
  /* Apply animation to this element */	
  -moz-animation: example1 15s linear infinite;
  -webkit-animation: example1 15s linear infinite;
  animation: example1 15s linear infinite;
 }
 .marqueeSlowAnimation {
  height: 30px;	
  overflow: hidden;
  position: relative;
  background: #1a1a1a;
  
 }
 .marqueeSlowAnimation div {
  font-size: 12px;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 30px;
  text-align: center;
  /* Starting position */
  -moz-transform:translateX(100%);
  -webkit-transform:translateX(100%);	
  transform:translateX(100%);
  /* Apply animation to this element */	
  -moz-animation: example1 10s linear infinite;
  -webkit-animation: example1 10s linear infinite;
  animation: example1 10s linear infinite;
 }
 /* Move it (define the animation) */
 @-moz-keyframes example1 {
  0%   { -moz-transform: translateX(100%); }
  100% { -moz-transform: translateX(-100%); }
 }
 @-webkit-keyframes example1 {
  0%   { -webkit-transform: translateX(100%); }
  100% { -webkit-transform: translateX(-100%); }
 }
 @keyframes example1 {
  0%   { 
  -moz-transform: translateX(100%); /* Firefox bug fix */
  -webkit-transform: translateX(100%); /* Firefox bug fix */
  transform: translateX(100%); 		
  }
  100% { 
  -moz-transform: translateX(-100%); /* Firefox bug fix */
  -webkit-transform: translateX(-100%); /* Firefox bug fix */
  transform: translateX(-100%); 
  }
 }

 .electroststicVideo {
  height: 220px;
  width: 140px;
  position: fixed;
  background: linear-gradient(45deg, #1a1a1a, #1a1a1a);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  bottom: 0;
  border:1px solid #333;
  border-radius:5px;
  left:0;
  margin-left: 10px;
  margin-bottom: 60px;
  padding: 0;
  }

  .electroststicVideoFullView {
      height: 100vh;
      width: 100%;
      position: fixed;
      background: linear-gradient(45deg, #1a1a1a, #1a1a1a);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      border: 1px solid #333;
      border-radius: 5px;
      top: 0;
      left: 0;
      z-index: 999999999999;
      margin: 0;
      overflow: hidden;
      
  }

  .electroststicVideo video {
    object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

  .electroststicVideoFullView video{
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    z-index: 1;
  }

    .opacityBg {
      height: 35px;
      width: 35px;
      padding-top: 5px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.6);
      color: white;
      border-color: rgba(0, 0, 0, 0.4);
      border: 1px solid rgba(0, 0, 0, 0.4);
      z-index: 1;
      opacity: 1;
  
      :hover {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px rgba(0, 0, 0, 0.3), 0px 1px 10px rgba(0, 0, 0, 0.3);
        border-color: rgba(0, 0, 0, 0.3);
        color: white;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
    .modalClose {
      height: 40px;
      width: 40px;
      padding: 8px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      background-color: #333;
      color: white;
      
    }
    .paddingZero{
     padding-top: 0;
    }
    .mrgnRight{
        margin-right: 10px;
    }

    .how-to-pay{
      border-top: 1px dashed #fff;
      width: 90%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      top: 0;
      left: 0;
      margin: 0;
    }

    .how-to-pay video{
      background-color: #1a1a1a;
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 1;
    }


    /*******Card Style start here *****/

    .myid {
      position: relative;
      top: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 17px;
      background: linear-gradient(126.14deg, #ae8902, #675100 99.48%);
      width: 100%;
      align-items: center;
      margin-bottom: 20px;
      padding: 10px;
  }

  .idConatainer{
    display: flex;
     width: 100%;
    align-items: center;
    margin-top: 18px
  }

  .id-detail{
    margin-left: 10px;
    word-wrap: break-word;
  }

  .dot-bar {
    position: absolute;
    height: 7.18%;
    width: 1.08%;
    top: 1.68%;
    right: 9.49%;
    bottom: 87.14%;
    max-width: 100%;
    cursor: pointer;
}

.myid-inner {
  border-radius: 17px;
  background-color: #5c4907;
  width: 100px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myid-inner img{
  width: 100%;
  border-radius: 50%
}

.id-name {
  font-size: 12px;
  color: white;
  font-weight: 500;
  position: absolute;
  top: 37px;
  left: 150px;
}

.id-url-name {
  font-size: 14px;
  color: white;
  font-weight: 500;
}
.id-url-text{
  color: #023a80;
  font-weight:bold;
  cursor: pointer;
  text-decoration:underline;
}

.user-id{
  font-size: 11px;
    color: white;
    margin-top: 10px;
}

.password{
  font-size: 11px;
    color: white;
}
.change-pass{
    font-size: 10px;
    color: #c6c1c1;
    margin-top: 5px;

}

.id-footer{
  width: 100%;
  padding: 8px;
  background-color: #000;
  margin-top: 15px;
  border-radius: 0px 0px 30px 30px;
  display: flex;
  justify-content: space-evenly;
}
.despositNwihdraw{
  display:flex; 
  align-items:center;
  font-size:14px;
  cursor: pointer;
}
.line{
  border-right: 1px solid #e5e5e5;
  box-sizing: border-box;
  width: 1px;
  height: 20px;
}
.createNewIdCard{
  display:grid;
  gap:1rem;
  padding-block:0rem 1rem;
  grid-template-columns:repeat(2, 1fr);
  margin-inline: auto;
  
}
.createNewIdCardContainer{
  padding: 0;
  position: relative;
}
.imgContainer{
  padding: .1rem;
  text-align: center;
  font-size: 12px;
  padding-bottom: 45px;
  height: 100%;
}

.imgContainer img{
  padding: .6rem;
  width: 100%;
  border-radius: 50%;
}


.createButton{
    padding:.2rem;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor: pointer;
    bottom: 0;
    position: absolute;
    width: 100%;
}
.createButton span{
  font-size: 12px;
}

@media (min-width:350px) {
  .createNewIdCard{
    grid-template-columns:repeat(3, 1fr)
  }
}

.display-block-header{
  width:100%;
  display: flex;
  align-items: center;
}

.display-block-wallet{
  width:100%;
}

/*animation*/
@keyframes inAnimation {
  0% {
    opacity: 0;
    max-height: 0px;
  }
  100% {
    opacity: 1;
    max-height: 600px;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
    max-height: 600px;
  }
  100% {
    opacity: 0;
    max-height: 0px;
  }
}